@media (max-width: 768px) {
  .site-logo .name {
    font-size: 1.5rem !important;
  }

  .carousel-caption h1 {
    font-size: 2rem !important;
  }
}

@media (min-width: 992px) {
  .card-columns {
    columns: 5;
  }
}