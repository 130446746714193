@import 'App-576.css';

@font-face {
  font-family: Leelawadee;
  src: url('../fonts/leelawadee.eot');
  src: url('../fonts/leelawadee.eot?#iefix') format('embedded-opentype'), url('//pimaxplus.com/fonts/leelawadee.woff2') format('woff2'), url('../fonts/leelawadee.woff') format('woff'), url('../fonts/leelawadee.ttf') format('truetype'), url('../fonts/leelawadee.svg') format('svg');
  font-display: swap;
}

::placeholder {
  font-style: italic;
  color: #ccc;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: #34222e;
}

a {
  color: #ce0000;
}

a:hover {
  color: #a70101;
  text-decoration: none;
}

p {
  margin-top: 30px;
  line-height: 1.88;
}

h2, h3, h4 {
  margin-top: 30px;
  color: #02124d;
  line-height: 1.5;
  letter-spacing: -2px;
  font-weight: 800;
}

h2 {
  font-size: 2.4rem;
}

blockquote {
  margin: 0;
  padding: 0;
  margin-top: 30px;
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding-left: 30px;
  border-left: 3px solid #e2434b;
  font-size: 1.4rem;
  font-style: italic;
  color: #34222e;
}

blockquote * {
    margin-top: 15px;
}

blockquote footer {
  font-size: .7rem;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 2px;
  color: #E19E67;
  font-style: normal;
  margin-bottom: 30px;
}

blockquote footer:before, blockquote small:before, blockquote .small:before {
  content: '\2014 \00A0';
}

.red {
  color: #ce0000;
}

.site-menu {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 2rem 5%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  z-index: 9000;
}

.site-menu .navbar-collapse.show .navbar-nav, .site-menu .navbar-collapse.collapsing .navbar-nav {
  background-color: #fff;
  padding-left: 1em;
  margin-top: 1em;
}

.site-menu .navbar-collapse.show .navbar-nav .nav-link, .site-menu .navbar-collapse.collapsing .navbar-nav .nav-link {
  color: #34222e !important;
}

.site-menu.scrolled {
  background-color: #fff;
  position: fixed;
  transition: 0.3s ease;
  box-shadow: 0 0 10px rgba(206, 0, 0, 0.3);
}

.navbar-toggler {
  background-color: rgba(255, 255, 255, 0.6);
  border: 2px solid #ce0000 !important;
} 

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(206,0,0, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.nav-link {
  margin-right: 30px;
  color: #fff !important;
  font-weight: 800;
  text-transform: uppercase;
}

.flags img {
  margin-right: 1rem;
  cursor: pointer;
}

.site-menu.scrolled .nav-link {
  color: #34222e !important;
}

.nav-link:hover, .site-menu.scrolled .nav-link:hover {
  color: #ce0000 !important;
  transition: ease 0.8s;
}

.site-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: margin 0.3s ease;
  text-decoration: none !important;
}

.site-logo .logo-img {
  flex-shrink: 0;
  line-height: 1;
}

.site-logo .logo-img img {
  max-height: 60px;
}

.site-logo .name-and-tagline {
  display: flex;
  flex-direction: column;
  padding-left: 7.5px;
}

.site-logo .name {
  font-family: Leelawadee;
  font-weight: 800;
  color: #34222e;
  letter-spacing: 1px;
  font-size: 2rem;
  line-height: 1;
}

.site-menu:not(.scrolled) .site-logo .name, .site-menu:not(.scrolled) .site-logo .tagline {
  color: #fff;
}

.site-logo .tagline {
  line-height: 1;
  text-transform: uppercase;
  font-size: .8rem;
  color: #34222e;
  margin-top: 5px;
}

.banner .carousel-item {
  height: 390px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner.webp .carousel-item {
  background-image: url(../img/1.webp);
}

.banner.no-webp .carousel-item {
  background-image: url(../img/1.jpg);
}

.main-banner .carousel-item {
  height: 100vh;
}

.banner .carousel-item::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .6;
  content: '';
  z-index: 2;
}

.banner .carousel-caption {
  bottom: 15%;
  left: 5%;
  border-left: 1px solid #e19e67;
  padding-left: 30px;
  text-align: left;
}

.banner .carousel-caption p {
  margin: 0;
  font-size: 1.5rem;
  text-transform: capitalize;
  font-weight: 300;
  line-height: 2;
}

.banner .carousel-caption h1 {
  margin: 0;
  font-size: 4rem;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: -2px;
}

.alternate {
  padding: 1rem 5%;
  background: #fee9d7;
  text-align: center;
}

.alternate svg {
  color: #ce0000;
}

.alternate div[class^='col'] {
  padding: 2rem;
}

.alternate h5 {
  margin: 30px 0 0 0;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.5;
}

.alternate p {
  margin-top: 13px;
}

.btn {
  border-radius: 2px;
  font-weight: 800;
  font-size: 1rem;
  padding: 15px 30px;
}

.btn-primary {
  color: #fff;
  background: #e2434b;
  border-color: #e2434b;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background: #ce0000;
  border-color: #ce0000;
}

.btn-secondary {
  color: #34222e;
  background: #fee9d7;
  border-color: #fee9d7;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
  background: #fdcea5;
  border-color: #fdc89b;
  color: #34222e;
}

.btn-secondary:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.cover-photo {
  width: 100%;
  border-radius: 2px;
}

.photo-shadow {
  box-shadow: 3px 3px 21px 3px rgba(0,0,0,0.75);
}

.footer {
  background: #e8e8e8;
  padding-top: 90px;
  padding-bottom: 120px;
}

.page-link {
  color: #ce0000;
  font-weight: bold;
}

.page-link:hover {
  color: #a70101;
}

.page-item.active .page-link {
  background-color: #ce0000;
  border-color: #a70101;
}

.pixelated {
  image-rendering: pixelated;
}